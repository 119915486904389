import React, { PureComponent, useEffect } from 'react';
import { graphql, navigate } from 'gatsby';
import sizes from 'react-sizes';
import {
  CaseStudyInfo,
  SEO,
  ResponsiveVideo,
  Layout,
  Title,
  CaseStudyNavigation
} from 'components';

class CaseStudy extends PureComponent {
  render() {
    const { data, location, pageContext } = this.props;
    const { pathname } = location;
    const { prev, next } = pageContext;
    const prevSlug = prev ? prev.frontmatter.slug : null;
    const nextSlug = next ? next.frontmatter.slug : null;
    const {
      clientName,
      ourSolutionText,
      resultsText,
      theBriefText,
      title,
      videoTitle
    } = data.markdownRemark.frontmatter;

    return (
      <Layout location={location}>
        <SEO
          pageSpecificTitle={`'${title}'`}
          pageSpecificDescription={
            clientName === 'Get Broadcasting'
              ? `Check out our '${title}' video.`
              : `Check out our '${title}' video for ${clientName}. Another happy Get Broadcasting client!`
          }
          pathname={location.pathname}
        />
        <CaseStudyNavigation prevSlug={prevSlug} nextSlug={nextSlug} />
        <Title text={title} pathname={pathname} />
        <CaseStudyInfo
          theBriefText={theBriefText}
          ourSolutionText={ourSolutionText}
          resultsText={resultsText}
          pathname={pathname}
        />
        <ResponsiveVideo videoTitle={videoTitle} pathname={pathname} />
      </Layout>
    );
  }
}

export default sizes(({ width }) => ({ viewportWidth: width && width }))(CaseStudy);

export const query = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      id
      frontmatter {
        clientName
        ourSolutionText
        resultsText
        services
        slug
        theBriefText
        title
        videoTitle
      }
    }
  }
`;
